<template>
  <div class="counters">
        <div class="count" v-for="cat in sortCats" v-bind:id="cat['id']"
             v-bind:key="cat.id"
             :style="{'background-color':cat.color}" v-on:click="click_on_cat(cat.id)" >
      <span>{{ cat['name']}}<span v-if="cat.name!==''">: </span>
        <span>
          {{ cat.count }}
        </span>
      </span>
          <i class="material-icons" v-show="selectedCat===cat.id" @click="showCatEdit">settings_applications</i>
        </div>
    <span @click="openAddCat" v-if="admin"> <i id="plus" class="material-icons">add</i></span>
  </div>
</template>

<script>
  export default {
    name: 'catBox',
    props: ['cats', 'clickedLength', 'admin'],
    components: {
    },
    data () {
      return {
        cat_box: false,
        edit_box: false,
        catName: '',
        catColor: '',
        selectedCat: null,

      }
    },
    computed: {
      length: function () {
        return this.clickedLength
      },
      sortCats: {
        get() {
          return Object.entries(this.cats).map(entry => entry[1])
              .sort((a, b) => a.rank - b.rank)
        },
        // setter
        set(newValue) {
          // Note: we are using destructuring assignment syntax here.
          return this.$emit('changeOrder', newValue)
        },
      }
    },
    methods: {
      closeAddCat () {
        this.$store.commit('setBorder', false)
      },
      openAddCat () {
        this.$emit('newCat')
      },
      click_on_cat (catID) {
        if (this.admin){
          if(this.length > 0){
            this.$emit("setCat", catID)
          }
          else{
            if(catID !== this.selectedCat && catID !==  's'){
              this.selectedCat = catID
            }
            else {
              this.selectedCat = null
            }
          }
        }
      },
      showCatEdit () {
        this.$emit("catEdit", this.selectedCat)
      },
    },
  }
</script>

<style scoped>

  .count{
    margin: 5px;
    display: block;
    float: left;
    padding: 3px 6px;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    font-weight: bold;
    border: 1px solid;
    min-width: 24px;
  }

  .counters {
    display: flow-root;
    position: sticky;
    background-color: #fff;
    top: 5px;
    font-size: inherit;
    border: 1px solid #000;
    max-width: 2000px;
    width: 98%;
    margin: 0 auto;
    z-index: 2;
  }

  .material-icons {
    cursor: pointer;
    vertical-align: top;
  }
  #plus {
    padding: 3px;
    margin: 5px;
    float: left;
  }

</style>

<template>
  <div class="counters">
    <draggable v-model="sortCats" >
      <transition-group>
        <div class="count" v-for="cat in sortCats" v-bind:id="cat['id']"
             v-bind:key="cat.id"
             :style="{'background-color':cat.color}" @click="click_on_cat(cat.id)">
      <span>
        {{ cat['name']}}
        <span v-if="Object.keys(count).length !== 0"><span v-if="cat.id!=='s'">:</span> {{count[cat.id]}}</span>
      </span>
          <i class="material-icons" v-show="selectedCat===cat.id" @click="showCatEdit">settings_applications</i>
        </div>
      </transition-group>
    </draggable>
    <span @click="openAddCat" v-if="admin"> <i id="plus" class="material-icons">add</i></span>
  </div>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'
  export default {
    name: 'catBox',
    props: ['cats', 'clickedLength', 'admin', 'catGroupID', 'count'],
    components: {
      draggable: VueDraggableNext,
    },
    data () {
      return {
        cat_box: false,
        edit_box: false,
        catName: '',
        catColor: '',
        selectedCat: null,
      }
    },
    computed: {
      length: function () {
        return this.clickedLength
      },

      sortCats: {
        get() {
          return Object.entries(this.cats).map(entry => entry[1])
              .sort((a, b) => a.rank - b.rank)
        },
        // setter
        set(newValue) {
          // Note: we are using destructuring assignment syntax here.
          return this.$emit('changeOrder', newValue)
        },
      }
    },
    methods: {
      openAddCat (catGroupID) {
        this.$emit('newCat', catGroupID)
      },
      closeAddCat () {
        this.$store.commit('setBorder', false)
      },
      click_on_cat (catID) {
        if (this.admin){
          if(this.length > 0){
            this.$emit("setCat", catID)
          }
          else{
            if(catID !== this.selectedCat && parseInt(catID) !==  0){
              this.selectedCat = catID
            }
            else {
              this.selectedCat = null
            }
          }
        }
      },
      showCatEdit () {
        this.$emit("catEdit", this.selectedCat)
      },
    },
  }
</script>

<style scoped>

  .count{
    margin: 5px;
    display: block;
    float: left;
    padding: 3px 6px;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    font-weight: bold;
    min-width: 24px;
    border: 1px solid;
  }

  .counters {
    display: flow-root;
    position: sticky;
    top: 5px;
    background-color: white;
    font-size: inherit;
    border: 1px solid #000;
  }

  .material-icons {
    cursor: pointer;
    vertical-align: top;
  }
  #plus {
    padding: 3px;
    margin: 5px;
    float: left;
  }
  .catGroup{
    display: flow-root;
  }

</style>
